import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Articles from "../../components/articles"

export default function Blog({ data }) {
  return (
    <Layout>
      <h2 className="text-4xl py-4 font-semibold">ブログ</h2>
      <hr className="my-4 md:my-8" />
      <Articles mdxNodes={data.allMdx.nodes} />
    </Layout>
  )
}

export const Head = () => <Seo title="Blog" />

export const query = graphql`
  query {
    allMdx(sort: {fields: frontmatter___date, order: DESC}) {
      nodes {
        frontmatter {
          title
          date(formatString: "yyyy.MM.DD")
          slug
          hero_image_alt
          hero_image {
            childImageSharp {
              gatsbyImageData
            }
          }
          tags
        }
        id
        excerpt
      }
    }
  }
`
